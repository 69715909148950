<template>
  <main>
    <div>
      <PageBlock bg-alt bg-skewed>
        <div class="content">
          <div class="head">
            <NuxtLink to="/">
              <img src="/images/logo/otago.svg" alt="Otago logo" />
              <span>Otago</span>
            </NuxtLink>
          </div>
          <h1>Simple OTA updates</h1>
          <span>Push new code to your react-native apps!</span>
          <div class="buttons">
            <BlueButton :href="getOauthUrl('google')">Google connect</BlueButton>
            <BlueButton :href="getOauthUrl('apple')">Apple connect</BlueButton>
            <!-- <BlueButton :href="getOauthUrl('github')">Github connect</BlueButton> -->
          </div>
          <p class="terms">
            By clicking connect, you agree to our
            <NuxtLink to="/legal/terms" target="_blank">Terms of Service</NuxtLink>
            and
            <NuxtLink to="/legal/privacy-policy" target="_blank">Privacy Policy</NuxtLink>.
          </p>
        </div>
      </PageBlock>
    </div>
  </main>
</template>

<script setup lang="ts">
const route = useRoute();

useHead({
  title: "Otago - Login",
});

const getOauthUrl = (provider: string) => {
  const params = Object.entries({
    redirect: route.query.redirect,
  })
    .filter(([, value]) => value)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
  return `/auth/${provider}${params ? "?" : ""}${params}`;
};
</script>

<style scoped>
main {
  @apply flex min-h-screen items-center py-8;

  > div {
    @apply flex w-full flex-col items-center;

    .head {
      @apply pb-6;

      a {
        @apply flex items-center tracking-wider text-blue-900;
      }

      img {
        @apply mr-1 h-6 sm:h-10;
      }

      span {
        @apply self-center text-xl font-semibold whitespace-nowrap dark:text-white;
      }
    }

    .content {
      @apply flex flex-col items-center text-center max-w-sm mx-auto;

      h1 {
        @apply text-2xl font-semibold text-blue-900 dark:text-white;
      }

      .buttons {
        @apply pt-12;

        a {
          @apply my-1 mx-2;
        }
      }
    }

    .terms {
      @apply mt-8 text-sm italic text-gray-500 dark:text-gray-400;

      a {
        @apply underline;
      }
    }
  }
}
</style>
